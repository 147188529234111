import * as React from "react";
import "../styles/main.scss";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavMobile from "../components/NavMobile";
import { SEO } from "../components/seo";
import { Link } from "gatsby";

import "@fontsource/maitree/300.css";
import "@fontsource/maitree/400.css";
const IndexPage = () => {
  return (
    <>
      <NavMobile isHome={true} />
      <Header />
      <main>
        <section className="section">
          <div className="section-wrapper"></div>
        </section>
        <section className="section">
          <div
            className="section-wrapper"
            style={{ marginTop: 0, paddingTop: 0 }}
          >
            <div className="text-container--full">
              <motion.h1
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="title"
              >
                Zdrowy uśmiech, <br />
                lepsze życie
              </motion.h1>
              <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Miladent to nowoczesny i kameralny gabinet stomatologiczny w
                centrum Namysłowa. Proponujemy leczenie w przyjaznej atmosferze
                i przestrzeni, oferując Państwu profesjonalny, dający komfort i
                bezpieczeństwo sprzęt. <br />
                Specjalizujemy się w wykonywaniu wysokiej jakości uzupełnień
                protetycznych. Pracujemy w powiększeniu oraz w oparciu o
                protokół cyfrowy dbając o najdrobniejsze szczegóły wykonywanych
                wypełnień.
              </motion.p>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-wrapper">
            <StaticImage
              objectFit="contain"
              className="img-container img-container--2"
              imgClassName="img-container--img"
              src="../images/hero-3.jpg"
              alt="lusterko dentystyczne"
              placeholder="blurred"
            />
            <StaticImage
              objectFit="contain"
              className="img-container"
              imgClassName="img-container--img"
              src="../images/hero-2.jpg"
              alt="lusterko dentystyczne"
              placeholder="blurred"
            />
          </div>
        </section>
        <section id="oferta" className="section">
          <div className="section-wrapper">
            <div className="text-container">
              <motion.h1
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="title"
              >
                Protetyka
              </motion.h1>
              <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Dbając o najwyższą jakość, estetykę i dokładność naszych prac
                współpracujemy z nowoczesnymi laboratoriami i centrami
                frezowania w Polsce. Używamy w naszej praktyce cyfrowego
                skanera, który pozwala na wyeliminowanie tradycyjnych wycisków,
                zwiększa dokładność prac i skraca czas ich wykonania.
              </motion.p>
            </div>
            <motion.div
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.2 },
              }}
              className="card card-row"
            >
              <Link to="/protetyka">
                <h3 className="card-title">Protetyka</h3>
                <StaticImage
                  className="card-img--container"
                  imgClassName=""
                  src="../images/offer-2.jpeg"
                  alt="model protezy zębów"
                  placeholder="blurred"
                />
                <div className="card-cta">
                  <p>zobacz</p>
                </div>
              </Link>
            </motion.div>
          </div>
        </section>
        <section className="section">
          <div className="section-wrapper section-wrapper--card">
            <motion.div
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.2 },
              }}
              className="card"
            >
              <Link to="/protetyka">
                <h3 className="card-title">Protetyka</h3>
                <StaticImage
                  className="card-img--container"
                  imgClassName=""
                  src="../images/offer-2.jpeg"
                  alt="model protezy zębów"
                  placeholder="blurred"
                />
                <div className="card-cta">
                  <p>zobacz</p>
                </div>
              </Link>
            </motion.div>
            <motion.div
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.2 },
              }}
              className="card"
            >
              <Link to="/profilaktyka">
                <h4 className="card-title">Profilaktyka</h4>
                <StaticImage
                  className="card-img--container"
                  imgClassName=""
                  src="../images/offer-1.jpeg"
                  alt="lusterko dentystyczne"
                  placeholder="blurred"
                />
                <div className="card-cta">
                  <p>zobacz</p>
                </div>
              </Link>
            </motion.div>
          </div>
        </section>
        <section className="section">
          <div className="section-wrapper">
            <motion.div
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.2 },
              }}
              className="card card-row"
            >
              <Link to="/profilaktyka">
                <h4 className="card-title">Profilaktyka</h4>
                <StaticImage
                  className="card-img--container"
                  imgClassName=""
                  src="../images/offer-1.jpeg"
                  alt="lusterko dentystyczne"
                  placeholder="blurred"
                />
                <div className="card-cta">
                  <p>zobacz</p>
                </div>
              </Link>
            </motion.div>
            <div className="text-container">
              <motion.h2
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="title"
              >
                Profilaktyka
              </motion.h2>
              <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Naszym celem jest trwała poprawa stanu uzębienia naszych
                pacjentów. Doceniamy wartość profesjonalnej profilaktyki.
                Wspieramy naszych pacjentów w kształtowaniu dobrych nawyków
                higienicznych, aby pięknymi efektami leczenia mogli cieszyć się
                przez długie lata.
              </motion.p>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-wrapper">
            <div className="text-container text-container--full">
              <motion.h2
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="title"
              >
                Nasz zespół
              </motion.h2>
              <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Lekarz dentysta Jolanta Łuszczyńska i higienistka Małgorzata
                Mucha, wspólnie tworzymy Miladent. Gabinet funkcjonuje
                nieprzerwanie od 2007r.. Systematycznie uczestniczymy w kursach
                i warsztatach, doskonaląc swoje umiejętności w zakresie
                stomatologii zachowawczej, protetyki oraz chirurgii. Dynamiczny
                rozwój nowoczesnej stomatologii motywuje nas do ciągłego
                doskonalenia się i podejmowania nowych wyzwań.
              </motion.p>
            </div>
          </div>
        </section>
        <div className="hero">
          <StaticImage
            className="hero-img--wrapper hero-img--wrapper-single"
            imgClassName="hero-img"
            src="../images/team-2.jpg"
            alt="zespół miladentu podczas pracy z pacjentem"
            placeholder="blurred"
          />
        </div>
        <section className="section">
          <div className="section-wrapper"></div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
